<template>
  <div class="js-common-wrap js-realtime">
    <div class="js-his-records-head">
      <label>5G+AR 智慧作业实时信息</label>
    </div>
    <div class="js-common-content js-realtime-content">
      <div class="js-realtime-table">
        <div class="js-realtime-table-box">
          <el-table
            class="js-table"
            :data="tableData"
            height="100%"
            empty-text="暂无数据"
          >
            <el-table-column prop="url" label="实时图片">
              <template slot-scope="scope">
                <img class="img" :src="scope.row.picUrl" alt="" />
              </template>
            </el-table-column>
            <el-table-column
              prop="createdName"
              label="上传人"
            ></el-table-column>
            <el-table-column
              prop="gmtCreated"
              label="上传时间"
            ></el-table-column>
            <el-table-column prop="name" label="作业名称"></el-table-column>
            <el-table-column prop="stepName" label="步骤"></el-table-column>
          </el-table>
        </div>
      </div>
      <ul class="js-realtime-list">
        <li>
          <p class="date">{{ currentDate }}</p>
          <p class="time">{{ currentTime }}</p>
        </li>
        <li class="icon-info">
          <i class="icon icon-real"></i>
          <div class="info-box">
            <label>当前在线</label>
            <p>
              <i class="num">{{ memberCount }}</i
              >人
            </p>
          </div>
        </li>
        <li class="icon-info">
          <i class="icon icon-runing"></i>
          <div class="info-box">
            <label>作业执行</label>
            <p>
              <i class="num">{{ operationNum }}</i
              >人
            </p>
          </div>
        </li>
        <li class="icon-info">
          <i class="icon icon-num"></i>
          <div class="info-box">
            <label>当前上报数量</label>
            <p>
              <i class="num">{{ tableData.length }}</i
              >次
            </p>
          </div>
        </li>
        <li>
          <p class="title">人员及作业情况</p>
          <div class="details">
            <div
              class="details-item"
              v-for="item in operaRecord"
              :key="item.id"
            >
              <img src="@/assets/images/userImg.png" alt="" />
              <div class="infos">
                <p>{{ item.name }}</p>
                <p class="fc">{{ item.state }}</p>
                <p class="fc">{{ item.opeName }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RTMClient, RTMChannelService } from "@/assets/js/RTMClient";
export default {
  name: "",
  components: {},
  data() {
    return {
      tableData: [],
      currentDate: "- ",
      currentTime: "-",
      timer: "",
      rtmCommonClient: "",
      rtmCommonChannel: "",
      realInfo: "",
      channelMsg: {
        url: "",
        id: "",
      },
      // 作业执行
      operationNum: 0,
      // 人员作业情况
      operaRecord: [],
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "getOnlineUsers"]),
    // 总人数
    memberCount() {
      console.log(this.getOnlineUsers)
      return Object.keys(this.getOnlineUsers).length;
    },
  },
  mounted() {
    clearInterval(this.timer);
    this.currentDate = this.$Utils.getNowDate();
    this.timer = setInterval(() => {
      this.getCurrentTime();
    }, 1000);

    this.rtmClient();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    rtmClient() {
      this.initialRoom();
    },
    initialRoom() {
      // rtmCommonChannel为异步第二步，它初始化完成后，2个对象就都有了，再执行事件绑定
      if (window?.GLOBAL?.state === "ready") {
        this.CommonRtcClient = window.GLOBAL.CommonRtcClient;
        this.CommonRongIMLib = window.GLOBAL.CommonRongIMLib;
        this.CommonRongCaller = window.GLOBAL.Caller;
        this.Events = window.GLOBAL.Events;

        this.getMessage();
      } else {
        setTimeout(this.initialRoom, 2000);
      }
    },
    getMessage() {
      const MESSAGES = this.CommonRongIMLib.Events.MESSAGES;

      this.CommonRongIMLib.addEventListener(MESSAGES, (event) => {
        // 处理消息队列
        event.messages.map((message) => {
          console.log(message);
          const memberId = message?.senderUserId;
          // 文本消息
          if (message.messageType === "RC:TxtMsg") {
            let msg = JSON.parse(message.content.content);
            let url = "";
            console.log(
              `-------------------------收到的${memberId}发送的消息${msg}---------`
            );
            // 判断是否是人脸识别
            if (msg.mode == "sop") {
              url = "api/csp/sop/v1/sopRealTime/newest/";
              setTimeout(() => {
                this.getRealInfo(url, msg.mark);
              }, 1000);
            }
            // 判断是否是人员作业情况消息
            if (msg.mode == "realTime") {
              let index = this.operaRecord.findIndex((item) => {
                return item.id == msg.mark.id;
              });
              if (index === -1) {
                this.operaRecord.push(msg.mark);
              } else {
                this.operaRecord[index] = msg.mark;
              }
            }
          }
        });
      });
    },
    getMsg() {
      console.log("--------------监听----------------");
      this.rtmCommonChannel.getMembers().then((res) => {
        this.memberCount = res.length;
      });

      this.rtmCommonChannel.on("MemberJoined", (memberId) => {
        // 你的代码：用户已加入频道。
        console.log(`--------------成员${memberId}加入--------------`);
        this.rtmCommonChannel.getMembers().then((res) => {
          this.memberCount = res.length;
        });
      });
      this.rtmCommonChannel.on("ChannelMessage", (message, memberId) => {
        // 获取token,channel
        if (message.messageType === "TEXT") {
          if (message.text) {
            let msg = JSON.parse(message.text);
            let url = "";
            console.log(
              `-------------------------收到的${memberId}发送的消息${msg}---------`
            );
            // 判断是否是人脸识别
            if (msg.mode == "sop") {
              url = "api/csp/sop/v1/sopRealTime/newest/";
              this.getRealInfo(url, msg.mark);
            }
            // 判断是否是人员作业情况消息
            if (msg.mode == "realTime") {
              let index = this.operaRecord.findIndex((item) => {
                return item.id == msg.mark.id;
              });
              if (index === -1) {
                this.operaRecord.push(msg.mark);
              } else {
                this.operaRecord[index] = msg.mark;
              }
            }
          }
        }
      });
    },
    getCurrentTime() {
      this.currentTime = this.$Utils.getNowTime();
    },
    getRealInfo(url, id) {
      let tmp = this.tableData;
      this.$axios({
        method: "get",
        url: `${url}${id}?type=1`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.realInfo = data.data;
          this.tableData = this.realInfo.concat(tmp);
          let list = this.tableData.map((item) => {
            return item.createdName;
          });
          this.operationNum = [...new Set(list)].length;
          this.$forceUpdate();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/realTimeInformation.scss";
</style>
